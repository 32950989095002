import React, { useState, useEffect } from "react";
import { debounce } from "./helpers.js";

const Navbar = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleScroll = debounce(() => {
    const currentScrollPos = window.pageYOffset;

    setVisible(
      (prevScrollPos > currentScrollPos &&
        prevScrollPos - currentScrollPos > 70) ||
        currentScrollPos < 10
    );

    setPrevScrollPos(currentScrollPos);
  }, 100);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, visible, handleScroll]);
  const navbarStyles = {
    position: "fixed",
    width: "100%",
    transition: "top 0.6s",
  };
  return (
    <div>
      <nav
      style={{ ...navbarStyles, top: visible ? "0" : "-12rem" }}
        class="navbar is-dark "
        role="navigation"
        aria-label="main navigation"
        height="3000"
      >
       
        <div class="navbar-brand">
          <a class="navbar-item" href="https://vk.com/obuvbelorussiivspb">
            Торговый дом Комфорт
          </a>
        </div>

        <div id="navbarBasicExample" class="navbar-menu is-active">
          <div class="navbar-start">
            <a class="navbar-item has-item-black" href="#about">
              О нас
            </a>
            <a class="navbar-item" href="#partners">
              Партнеры
            </a>
            <a class="navbar-item" href="#contacts">
              Контакты
            </a>
          </div>
        
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
